import * as Sentry from '@sentry/astro';
import config from 'config/shared';
import { isProduction } from 'utils/env';

Sentry.init({
	dsn: 'https://c74f7db661ae4cad8d94282c184d08f9@sentry.pszn.cz/232',

	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.captureConsoleIntegration({ levels: ['error'] }),
	],
	environment: isProduction() ? 'production' : 'development',
	release: config.version,

	// Define how likely traces are sampled. Adjust this value in production,
	// or use tracesSampler for greater control.
	tracesSampleRate: isProduction() ? 0.1 : 1.0,

	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// result in 25% of transactions being profiled (0.5*0.5=0.25)
	profilesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: isProduction() ? 0.01 : 1.0,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
});
